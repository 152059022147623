import Layout from 'components/layout/Layout';
import useUser from 'hooks/sso/useUser';
import { HeaderLinks } from 'components/header/Header';
import Link from 'next/link';
import './errorPage.scss';

export type ErrorPageType = {
  children?: React.ReactNode;
  heading?: React.ReactNode;
  text?: React.ReactNode;
  bottomText?: React.ReactNode;
};

export default function ErrorPage(props: ErrorPageType) {
  const {
    children,
    heading = 'This page is temporarily unavailable',
    text = 'Please try again soon.',
    bottomText = (
      <>
        Stay entertained <Link href={HeaderLinks.LIVE}>with more content from 9Now</Link> while you wait.
      </>
    ),
  } = props;

  const [user, isLoggedIn] = useUser();

  return (
    <Layout userFirstName={user.settings?.firstName} isLoggedIn={isLoggedIn}>
      <div className="error_page">
        {heading && <h1 className="error_page__heading page-heading">{heading}</h1>}
        {text && <p className="error_page__text">{text}</p>}
        {bottomText && <p className="error_page__text">{bottomText}</p>}
        {children}
      </div>
    </Layout>
  );
}
